import { configureStore } from "@reduxjs/toolkit";
import navbarSlice from "./navbar";
import thumbSlider from "./ThumbSlider";

const store=configureStore({
    reducer:{
        'navbar':navbarSlice.reducer,
        'thumbSlider':thumbSlider.reducer
    }
})

export default store;