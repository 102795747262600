import {createSlice} from '@reduxjs/toolkit';

const thumbSlider=createSlice({
    name:'thumbSlider',
    initialState:{activeSlide:0,activeCarosel:1,activeMblCarousel:1},
    reducers:{
        setActiveSlide(state,action){
            state.activeSlide=action.payload;
        },
        setActiveCarousel(state,action){
            state.activeCarosel=action.payload;
        },
        setActiveMblCarousel(state,action){
            state.activeMblCarousel=action.payload;
        }
    }
})

export const thumbSliderActions=thumbSlider.actions;
export default thumbSlider;