import {createSlice} from '@reduxjs/toolkit';

const navbarSlice=createSlice({
    name:'navbar',
    initialState:{activeNavElement:'Home'},
    reducers:{
        setActiveNavElement(state,action){
            state.activeNavElement=action.payload;
        }
    }
})

export const navbarActions=navbarSlice.actions;
export default navbarSlice;