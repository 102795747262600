import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Routes,Route,BrowserRouter} from 'react-router-dom';
import {lazy,Suspense} from 'react';

// import Pagenotfound from './components/Pagenotfound';
// import Home from './components/Home';
// import MLOps from './components/Mlops';
// import LLMOps from './components/LLMOps';
// import MyRoles from './components/MyRoles';
// import HealthCare from './components/HealthCare';
// import Manufacturing from './components/Manufacturing';
// import Retail from './components/Retail';
// import Pharma from './components/Pharma';
// import BFSI from './components/BFSI';
// import ContactUs from './components/ContactUs';
// import AboutUs from './components/AboutUs';
// import Resources from './components/Resources';
// import Blogs from './components/Blogs';
// import Privacypolicy from './components/Privacypolicy';
// import Registration from './components/Register';

const Pagenotfound=lazy(()=>import ('./components/Pagenotfound'));
const Home=lazy(()=>import('./components/Home'));
const MLOps=lazy(()=>import('./components/Mlops'));
const LLMOps=lazy(()=>import('./components/LLMOps'));
const MyRoles=lazy(()=>import('./components/MyRoles'));
const HealthCare=lazy(()=>import('./components/HealthCare'));
const Manufacturing=lazy(()=>import('./components/Manufacturing'));
const Retail=lazy(()=>import('./components/Retail'));
const Pharma=lazy(()=>import('./components/Pharma'));
const BFSI=lazy(()=>import('./components/BFSI'));
const ContactUs=lazy(()=>import('./components/ContactUs'));
const AboutUs=lazy(()=>import('./components/AboutUs'));
const Resources=lazy(()=>import('./components/Resources'));
const Blogs=lazy(()=>import('./components/Blogs'));
const Privacypolicy=lazy(()=>import('./components/Privacypolicy'));
const Registration=lazy(()=>import('./components/Register'));
const RegistrationNew=lazy(()=>import('./components/Register/registerdup'))

function App() {
  return (
    <>
      <BrowserRouter>
      <Suspense>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/predictive-ai' element={<MLOps/>} />
          <Route path='/generative-ai' element={<LLMOps/>} />
          <Route path='/by-roles' element={<MyRoles/>} />
          <Route path='/healthcare' element={<HealthCare/>} />
          <Route path='/manufacturing' element={<Manufacturing/>} />
          <Route path='/retail' element={<Retail/>} />
          <Route path='/Lifesciences' element={<Pharma/>} />
          <Route path='/bfsi' element={<BFSI/>} />
          <Route path='/contact-us' element={<ContactUs/>} />
          <Route path='/about-us' element={<AboutUs/>} />
          <Route path='/resources' element={<Resources/>} />
          <Route path='/privacy-policy' element={<Privacypolicy/>} />
          {/* <Route path='/register' element={<Registration/>} /> */}
          <Route path='/register' element={<RegistrationNew/>} />
          <Route path='/blogs/:id' element={<Blogs/>}/>
          <Route path='*' element={<Pagenotfound/>}></Route>
        </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
